<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select
              v-model="nameType"
              placeholder="状态"
              @change="selectNameType"
              style="width: 120px"
            >
              <el-option
                v-for="item in nameTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              class=""
              placeholder="搜索关键字"
              prefix-icon="el-icon-search"
              v-model="searchValue"
              @input="inputSearchValue"
              @keyup.enter.native="search"
              style="width: 260px"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchData.timeType"
              placeholder="状态"
              style="width: 120px"
            >
              <el-option
                v-for="item in timeTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="searchData.selectTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="search"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </template>

      <template v-slot:table="row">
        <el-table :data="tableData" :height="row.heightText" v-if="row.height">
          <el-table-column align="center" label="序号" width="60">
            <template slot-scope="scope">{{
              scope.$index + 1 + (currentPage - 1) * pageSize
            }}</template>
          </el-table-column>
          <el-table-column
            prop="avatarUrl"
            align="center"
            label="头像"
            width="210"
          >
            <template slot-scope="scope">
              <el-avatar :src="scope.row.avatarUrl"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column
            prop="nickName"
            align="center"
            label="昵称"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="realName"
            align="center"
            label="真实姓名"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="inviteCode"
            align="center"
            label="邀请码"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="recommenderInviteCode"
            align="center"
            label="推荐人邀请码"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="bindTime_text"
            align="center"
            label="绑定时间"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="createTime_text"
            align="center"
            label="注册时间"
            width="200"
          >
          </el-table-column>
        </el-table>
      </template>
      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum"
          >
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData, getNextDayTimestamp, isEmpty, parseTime, parseTimeArray} from '../../../assets/js/utils'
  import {plusDownloadExcelTask} from '../../../store/excel'

  export default {
    name: 'bindingMember',
    data() {
      return {
        showStartEndTime: null,
        loading: false,
        tableData: [], // 列表数据
        nameType: '1', //名称类型 1昵称 2真实姓名
        searchValue: '', //用户输入的关键字
        searchData: {
          selectTime: [],
          nickName: '', // 用户昵称
          realName: '', // 真实姓名
          timeType: 3, // 时间类型 关注、注册、绑定
          inviteCode: '', //绑定的用户邀请码
          recommenderInviteCode: ''//绑定的推荐人邀请码
        //   settledAmount: '0',
        //   waitSettleAmount: '0',
        },
        nameTypeArray:[
          {id:'1',name:'昵称'},
          {id:'2',name:'真实姓名'},
          {id:'3',name:'邀请码'},
          {id:'4',name:'推荐人邀请码'}
        ],
        timeTypeArray:[
          {id:3,name:'绑定时间'},
          {id:2,name:'注册时间'}
        ],
        /* 分页参数 */
        pageSize: 10,
        totalListNum: 10,
        currentPage: 1,
        showSettleDetailVisible:false,
        settleDetail:{},
      }
    },
    created() {
      this.getList();
    },
    methods: {
        // 选择名字类型
        selectNameType(val) {
            if (val == 1) {
                this.searchData.nickName = this.searchValue;
            } else if (val == 2) {
                this.searchData.realName = this.searchValue;
            } else if (val == 3) {
                this.searchData.inviteCode = this.searchValue;
            } else if (val == 4) {
                this.searchData.recommenderInviteCode = this.searchValue;
            }
        },
        // 输入关键字时候出发
        inputSearchValue(val) {
            if (this.nameType == 1) {
                this.searchData.nickName = val;
            } else if (this.nameType == 2) {
                this.searchData.realName = val;
            } else if (this.nameType == 3) {
                this.searchData.inviteCode = val;
            } else if (this.nameType == 4) {
                this.searchData.recommenderInviteCode = val;
            }
        },
        /**
       * 拼接搜索参数
       */
      jointSearchData() {
        let data = {
          pageNo: this.currentPage,
          pageSize: this.pageSize,
        };
        data = Object.assign(data, this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;
        let startTime = '';
        let endTime = '';

        if (!isEmpty(selectTime)) {
          let times = selectTime.map(res => new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }
        data.startTime = startTime;
        data.endTime = endTime;
        delete data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search() {
        this.currentPage = 1;
        this.getList();
        this.showStartEndTime = this.searchData.selectTime;
      },
      //   获取主列表
      getList() {
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.memberApi.getBindStoreUser, {params: data}).then(res => {
            let list = res.data.records || [];
            parseTimeArray(list, ['bindTime','createTime']);
            this.tableData = list;
            this.totalListNum = res.data.total;
            resolve(res);
          }).catch(res => {
            this.tableData = [];
          }).finally(res => {
            this.loading = false;
          })
        })
      },
      /* 分页 */
      handleSizeChange(val) {
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getList();
      },
      /**
       * 导出excel
       */
      downloadExcel() {
        let searchData = this.jointSearchData();
        let data = {
          name: '绑定会员人员表',
          params: {
            excelType: 1010,
            excelParameterJson: JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this, data);
      },
      showDetail(orderId){
        this.$router.push({
          path:'/supplierSettleDetail',
          query:{
            orderId:orderId,
          }
        });
      },
    }
  }

</script>

<style>
</style>
